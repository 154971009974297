import './App.css';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'
import React, { useEffect, useState } from 'react';

function App() {

  const [geoStatus, setGeoStatus] = useState("initial");
  const [forecast, setForecast] = useState(null);
  const [input, setInput] = useState("");
  const [displaySuggestions, setDisplaySuggestions] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [fetchCity, setFetchCity] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rainingTime, setRainingTime] = useState(null)
  const [rainingTimeIntensityImage, setRainingTimeIntensityImage] = useState(null)

  const errorMessages = {
    "coordsOutOfRange": "Ľutujeme, vami zadané miesto je mimo rozsah predpovede.",
    "geolocationNotSupported": "Ľutujeme, ale váš prehliadač nepodporuje geolokáciu.",
    "geolocationNotAllowed": "Pre zobrazenie predpovede pre vašu aktuálnu polohu prosím povoľte jej získanie v prehliadači."
  }

  const backendApiUrl = "https://ploszek.com/pocasie/forecast"

  // const rainfallIntensity = {
  //   "Mierny dážď": 0.05,
  //   "Dážď": 0.22,
  //   "Silný dážď": 1.90
  // }

  const citiesDict = {
    "BA": { name: "Bratislava" },
    "TT": { name: "Trnava" },
    "TN": { name: "Trenčín" },
    "NR": { name: "Nitra" },
    "ZA": { name: "Žilina" },
    "BB": { name: "Banská Bystrica" },
    "PO": { name: "Prešov" },
    "KE": { name: "Košice" }
  }

  const rainfallIntensityDict = {
    "No rain": { intensity: 0, image: "sunny", desc: "Bez dažďa" },
    "Light": { intensity: 0.05, image: "light", desc: "Mierny dážď" },
    "Moderate": { intensity: 0.22, image: "moderate", desc: "Dážď \n." },
    "Heavy": { intensity: 1.90, image: "heavy", desc: "Silný dážď" }
  }

  useEffect(() => {
    console.log(geoStatus)
    if (geoStatus === true)
      getRainingTime()
  }, [forecast])

  const openWeatherApiKey = '36f6a9ee0c7a79ac819fd4cd667679ab'

  const fetchCoordinates = async () => {
    if (!navigator.geolocation) {
      setGeoStatus(false);
      setForecast('geolocationNotSupported')
    } else {
      setGeoStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setGeoStatus(null);
        fetchCityName(position.coords.latitude, position.coords.longitude)
      }, () => {
        setGeoStatus(false);
        setForecast("geolocationNotAllowed")
      });
    }
  }

  const fetchForecast = (lat, lon) => {
    setGeoStatus('loading')
    const apiUrl = backendApiUrl + `?lat=${lat}&lon=${lon}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.success)
        setForecast(data.forecast)
        setGeoStatus(data.success)
      });
  }

  const fetchCityLocation = (cityName) => {
    console.log("Fetching city location.")
    const apiUrl = `https://api.openweathermap.org/geo/1.0/direct?q=${cityName},SK&limit=10&appid=${openWeatherApiKey}`
    fetch(apiUrl)
      .then(response => response.json())
      .then(response => fetchForecast(response[0].lat, response[0].lon));
    setFetchCity(false)
  }

  const fetchCityName = (lat, lon) => {
    const apiUrl = `https://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=10&appid=${openWeatherApiKey}`
    fetch(apiUrl)
      .then(response => response.json())
      .then(response => setSelectedCity(response[0].name));
    fetchForecast(lat, lon)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => fetchCities(input), 500);
    return () => clearTimeout(timeoutId);
  }, [input])

  useEffect(() => {
    if (fetchCity)
      fetchCityLocation(selectedCity)
  }, [fetchCity])

  const sortNames = (a, b) => a.name.length - b.name.length

  const options = {
    method: 'GET',
    headers: {
      'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com',
      'X-RapidAPI-Key': 'e084f8824fmsh2cbe2bd36509993p1be23ejsnbb43e5ab57cc'
    }
  };

  const fetchCities = (input) => {
    setIsLoading(true)
    if (input.length > 0) {
      console.log("fetching")
      fetch(`https://wft-geo-db.p.rapidapi.com/v1/geo/cities?` +
        `countryIds=SK` +
        `&namePrefix=${input}&types=CITY`, options)
        .then(response => response.json())
        .then((response) => {
          var result = response.data.sort(sortNames)
          result = result.map(function (row) { row.name = row.name.replace(" District", ""); return row })
          setDisplaySuggestions(result)
        })
        .catch(err => console.error(err));
    } else {
      setDisplaySuggestions([])
    }
    setIsLoading(false)
  }

  const getRainingTime = () => {
    for (let i = 1; i <= forecast.length; i++) {
      const rainfallIntensity = getRainfallIntensity(forecast[i - 1])
      if (rainfallIntensity !== rainfallIntensityDict["No rain"]) {
        setRainingTime(i * 5 + " minút - " + rainfallIntensity.desc + "")
        setRainingTimeIntensityImage(assignImgToForecast(forecast[i - 1]))
        return
      }
    }
    setRainingTime(rainfallIntensityDict["No rain"].desc)
    setRainingTimeIntensityImage(assignImgToForecast(forecast[0]))
  }

  const assignImgToForecast = (rainfall) => {
    const rainfallIntensity = getRainfallIntensity(rainfall)
    return <img src={rainfallIntensity.image + ".png"} className='fluid-image' alt={rainfallIntensity.image} />
  }

  const getRainfallIntensity = (intensity) => {
    if (intensity < rainfallIntensityDict["Light"].intensity)
      return rainfallIntensityDict["No rain"]
    else if (intensity < rainfallIntensityDict["Moderate"].intensity)
      return rainfallIntensityDict["Light"]
    else if (intensity < rainfallIntensityDict["Heavy"].intensity)
      return rainfallIntensityDict["Moderate"]
    else
      return rainfallIntensityDict["Heavy"]
  }

  return (
    <div className='container py-2'>
      <div className="main justify-content-center">
        <h1 className='display-3 mb-5 p-4 text-center'>Predpoveď počasia pomocou extrapolácie radarových snímkov</h1>
        <div className='row justify-content-center'>
          <div className='row text-center justify-content-sm-center'>
            {
              Object.entries(citiesDict).map(([key, value]) => {
                return (
                  <div className='col-sm-auto px-1'>
                    <div className='d-flex flex-row btn-custom shadow-sm my-2' onClick={function () { setSelectedCity(value.name); setFetchCity(true) }}>
                      <div className='py-0 px-2 rounded-left text-white bg-teal'><i className="fas fa-map-marker-alt"></i></div>
                      <div className='py-0 px-2 rounded-right border-teal'>{value.name}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='row justify-content-center mt-4 w-100'>
          <div className='col-sm-auto'>
            <div className="input-group">
              <AsyncTypeahead
                id="searchCity"
                labelKey="name"
                filterBy={() => true}
                onSearch={value => {
                  console.log(value)
                  setInput(value)
                }}
                ignoreDiacritics={true}
                onChange={(selected) => {
                  console.log(selected)
                  if (selected.length > 0) {
                    setSelectedCity(selected[0].name)
                    fetchForecast(selected[0].latitude, selected[0].longitude)
                  }
                  // setFetchCity(true)
                }}
                placeholder="Vyberte mesto..."
                promptText="Zadajte minimálne 3 znaky pre hľadanie"
                isLoading={isLoading}
                options={displaySuggestions}
              />
              <div className="input-group-append">
                <span className="input-group-text rounded-right bg-dark-grey text-white"><i className="fas fa-search-location mx-1"></i>Hľadať</span>
              </div>

            </div>
          </div>

          <div className='col-sm-auto pt-1'>
            <p className='lead text-center'>alebo</p>
          </div>
          <div className='col-sm-auto mx-3 pt-1'>
            <div className='row btn-custom shadow-sm justify-content-center' onClick={fetchCoordinates}>
              <div className='col-sm-auto py-1 rounded-left text-white bg-scarlet'><i className="fas fa-map-marked-alt p-1"></i></div>
              <div className='col-sm-auto py-1 rounded-right border-scarlet'>Získať pozíciu</div>
            </div>
          </div>
        </div>
        <hr className='my-4' />
        <h1 className='display-5 p-3 text-center'>Predpoveď{selectedCity ? ` pre mesto ${selectedCity}:` : ":"}</h1>
        {rainingTime ?
          <div className='row justify-content-center mb-3'>
            <div className='col-sm-auto pt-2'>
              <p className='lead text-center'>Očakávajte dážď o: {rainingTime}</p>
            </div>
            <div className='col-sm-auto px-0'>
              {rainingTimeIntensityImage}
            </div>
          </div>
          : ''
        }
        {geoStatus === "loading" ?
          <div className="spinner-border" role="status">
          </div>
          : geoStatus ?
            forecast != null ? <div className='container scrollable-group'> <div className='row text-center'>
              {forecast.map((item, index) => {
                return (
                  <div className='col lead'>
                    <div className='card'>
                      <div className="card-body">
                        <p className="card-text">{(index + 1) * 5} min.</p>
                      </div>
                      <div className='card-body'>
                        {assignImgToForecast(item)}
                        <div className="card-body">
                          <p className="card-text">{getRainfallIntensity(item).desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })} </div> </div> : <p className='lead text-secondary text-center'>Prosím, vyberte mesto pre predpoveď</p>
            :
            <p className='lead text-danger'>
              {errorMessages[forecast]}
            </p>
        }
      </div>
    </div >
  );
}


export default App;
